
@mixin layout-width($items, $gap: $gap-size) {

	// Sets component WIDTH in %
	// Ex: @include layout-width(2, 0);

	$width: percentage(1/$items);

	@if $gap == 0 {
		$gap: 0rem; // sass-lint:disable-line zero-unit
	}

	width: calc(#{$width} - #{$gap});

}

// Calculate ratio to precent.
@function set-ratio-percent-function($width, $height) {

	// @include ratio('square');
	// @include ratio(1,1);

	// @include ratio('classic');
	// @include ratio(4,3);

	// @include ratio('wide'); Default
	// @include ratio(16,9); Default

	// @include ratio('cinema');
	// @include ratio(2.35,1);
	// $width: 16;
	// $height: 9;

	@if $width == 'square' {

		$width: 1;
		$height: 1;

	}

	@if $width == 'classic' {

		$width: 4;
		$height: 3;

	}

	@if $width == 'wide' {

		$width: 16;
		$height: 9;

	}

	@if $width == 'cinema' {

		$width: 2.35;
		$height: 1;

	}

	@return $height / $width * 100%;
}

// Set component to a ratio based on width.
@mixin ratio($width: 16, $height: 9) {

	position: relative;
	overflow: hidden;

	&::before {

		display: block;
		content: '';
		width: 100%;
		padding-top: set-ratio-percent-function($width, $height);

	}

}

// Set component to a ratio based on width.
// Min height is base on ratio, but expand if content needs more height.
@mixin ratio-min-height($width: 16, $height: 9) {

	position: relative;
	overflow: hidden;

	&::before {

		content: '';
		display: block;
		float: left;
		padding-bottom: set-ratio-percent-function($width, $height);

	}

	&::after {
		content: '';
		display: table;
		clear: left;
	}
}

.global__aspect-ratio-square {
	@include ratio('square');
}

.global__aspect-ratio-classic {
	@include ratio('classic');
}

.global__aspect-ratio-wide {
	@include ratio('wide');
}

.global__aspect-ratio-cinema {
	@include ratio('cinema');
}

@mixin rotate-on-hover {
	transform: rotate(90deg);
	transition: transform .6s ease;
}


//Menu animation from white to black
@mixin toggle-menu-animation-close ($type: color) {
	#{$type}: $color-white;
	transition: $type 0s ease .25s;
}

@mixin toggle-menu-animation-open ($type: color) {
	#{$type}: $color-black;
	transition: $type 0s ease .14s;
}

@mixin break-word {
	// word-break: normal; // sass-lint:disable-line no-duplicate-properties
	// word-break: break-word; // sass-lint:disable-line no-duplicate-properties
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}
